import { red } from '@material-ui/core/colors';
import "@fontsource/old-standard-tt";
import "@fontsource/open-sans";
import "@fontsource/roboto";
import "@fontsource/lora";

import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          //background: "linear-gradient(red , red)bottom left/ 50% 15px, linear-gradient(blue, blue) top right / 50% 15px, linear-gradient(brown, brown) top left/ 15px 50%, linear-gradient(orange, orange) bottom right / 15px 50%, #fff",
          //backgroundRepeat:"no-repeat",
        },
        gatsbyFocusWrapper: {
          height: "100%",
          width: "100%",
        },
        ___gatsby: {
          height: "100%",
          width: "100%",
        }
        
      },
    },
  },
  typography: {
    fontFamily: [
      'Old Standard TT',
      'serif',
    ].join(','),
    customClass: [
      'Roboto',
      'sans serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
